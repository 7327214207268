import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    NotesClient,
    FacilityNoteCensusDto,
    UnsignedNoteCensusDto,
    VisitDto,
    NoteTypes,
    CensusBadgeDto,
    CensusBadgeRequestDto,
    CensusClient,
    FacilityNotesWithOptionsDto,
} from '@medone/medonehp-api-client';

import { RootState, AppDispatch, AppThunk } from '../../../../shared/store';
import { handleError } from '../../../../shared/common/HandleErrors';

import { UnsignedNotesState, initialState, unsignedNotesBadgesAdapter } from './models';
import { fetchFacilities } from '../admin/slice.facilities';
import { fetchPatientTypes } from '../admin/slice.patient-types';
import { Axios } from '../../../../shared/common/http';
import { ValueLabelOption } from '../../../../shared/common/helpers';
import moment from 'moment';

export const unsignedNotesSlice = createSlice({
    name: 'unsignedNotes',
    initialState,
    reducers: {
        setError: (state: UnsignedNotesState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },

        setUnsignedNotes: (state: UnsignedNotesState, action: PayloadAction<FacilityNoteCensusDto[]>) => {
            state.errorMessage = null;
            state.unsignedNotes = action.payload;
        },

        setUnsignedNotesTab: (state: UnsignedNotesState, action: PayloadAction<string>) => {
            state.unsignedNotesTab = action.payload;
        },

        setUnsignedNoteOptions: (state: UnsignedNotesState, action: PayloadAction<FacilityNotesWithOptionsDto>) => {
            state.providerOptions = (action.payload?.providerOptions || []).map((x) => {
                return {
                    label: x.value,
                    value: x.key,
                } as ValueLabelOption;
            });

            state.facilityOptions = (action.payload?.facilityOptions || []).map((x) => {
                return {
                    label: x,
                    value: x,
                } as ValueLabelOption;
            });

            state.roomNumberOptions = (action.payload?.roomNumberOptions || []).map((x) => {
                return {
                    label: x,
                    value: x,
                } as ValueLabelOption;
            });

            state.dateOfServiceOptions = (action.payload?.dateOfServiceOptions || []).map((x) => {
                const date = moment(x).format('L');

                return {
                    label: date,
                    value: date,
                } as ValueLabelOption;
            });

            state.expirationDayOptions = (action.payload?.expirationDayOptions || []).map((x) => {
                return {
                    label: `in ${x} days`,
                    value: x,
                } as ValueLabelOption;
            });
        },

        setCurrentUnsignedNote: (state: UnsignedNotesState, action: PayloadAction<UnsignedNoteCensusDto>) => {
            state.currentUnsignedNote = action.payload;
        },

        setCurrentUnsignedNoteType: (state: UnsignedNotesState, action: PayloadAction<NoteTypes>) => {
            state.currentUnsignedNote = { ...state.currentUnsignedNote, noteType: action.payload } as UnsignedNoteCensusDto;
        },

        setUnsignedNoteVisit: (state: UnsignedNotesState, action: PayloadAction<VisitDto>) => {
            if (action.payload != null) {
                const newUnsignedNotes = [...state.unsignedNotes].map((newUnsignedNote) => {
                    const newNotes = [...(newUnsignedNote.notes ?? [])];
                    const note = newNotes.find((x) => x.visit.id === action.payload.id);

                    if (note != null) {
                        note.visit = action.payload;
                    }

                    return { ...newUnsignedNote, notes: newNotes } as FacilityNoteCensusDto;
                });

                state.unsignedNotes = newUnsignedNotes;

                if (state.currentUnsignedNote && action.payload.id === state.currentUnsignedNote.visit.id) {
                    state.currentUnsignedNote = { ...state.currentUnsignedNote, noteType: action.payload.noteType, visit: action.payload } as UnsignedNoteCensusDto;
                }
            }
        },

        setUnsignedBadgeData: (state: UnsignedNotesState, action: PayloadAction<CensusBadgeDto[]>) => {
            unsignedNotesBadgesAdapter.setMany(state.unsignedBadges, action.payload);
        },

        setStagedNoteBadgeData: (state: UnsignedNotesState, action: PayloadAction<CensusBadgeDto[]>) => {
            unsignedNotesBadgesAdapter.setMany(state.stagedNoteBadges, action.payload);
        },
    },
});

export const { setCurrentUnsignedNote, setCurrentUnsignedNoteType } = unsignedNotesSlice.actions;

export function fetchUnsignedNotes(cbOption?: string) {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(unsignedNotesSlice.actions.setUnsignedNotesTab(cbOption));

        const { auth, admin } = getState();
        const { account } = auth;
        const { localAccountId } = account;
        const client = new NotesClient(null, Axios);

        try {
            const response = cbOption && cbOption === 'all' ? await client.getAllUnsignedWithCensus('') : await client.getAllUnsignedWithCensus(localAccountId);

            if (response.result.succeeded) {
                if (!admin.patientTypes) {
                    await dispatch(fetchPatientTypes());
                }

                if (!admin.facilities) {
                    await dispatch(fetchFacilities());
                }

                const facilityNotes = response.result.entity?.facilityNotes ?? [];
                const allNotes = facilityNotes.flatMap((n) => n.notes);

                dispatch(unsignedNotesSlice.actions.setUnsignedNotes(allNotes));
                dispatch(unsignedNotesSlice.actions.setUnsignedNoteOptions(response.result.entity));
            } else {
                handleError(response, () => dispatch(unsignedNotesSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(unsignedNotesSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchUnsignedBadgeData(badgeDto: CensusBadgeRequestDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new CensusClient(null, Axios);

        try {
            const response = await client.getBadgeData(badgeDto);

            if (response.result.succeeded) {
                dispatch(unsignedNotesSlice.actions.setUnsignedBadgeData(response.result.entity));
            } else {
                handleError(response, () => dispatch(unsignedNotesSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(unsignedNotesSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchStagedNoteBadgeData(badgeDto: CensusBadgeRequestDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new CensusClient(null, Axios);

        try {
            const response = await client.getBadgeData(badgeDto);

            if (response.result.succeeded) {
                dispatch(unsignedNotesSlice.actions.setStagedNoteBadgeData(response.result.entity));
            } else {
                handleError(response, () => dispatch(unsignedNotesSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(unsignedNotesSlice.actions.setError(error.toString())));
        }
    };
}

export const unsignedBadgeSelectors = unsignedNotesBadgesAdapter.getSelectors();

export const selectUnsignedNotes = (state: RootState) => state.unsignedNotes.unsignedNotes;
export const selectCurrentUnsignedNote = (state: RootState) => state.unsignedNotes.currentUnsignedNote;
export const selectProviderOptions = (state: RootState) => state.unsignedNotes.providerOptions;
export const selectFacilityOptions = (state: RootState) => state.unsignedNotes.facilityOptions;
export const selectRoomNumberOptions = (state: RootState) => state.unsignedNotes.roomNumberOptions;
export const selectDateOfServiceOptions = (state: RootState) => state.unsignedNotes.dateOfServiceOptions;
export const selectExpirationDayOptions = (state: RootState) => state.unsignedNotes.expirationDayOptions;

export default unsignedNotesSlice.reducer;
