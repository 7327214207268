import { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Alert, Button, Col, Collapse, Row, Tag } from 'antd';
import { CensusDto, CensusFiltersDto, CensusGroupDto, FacilityDto, Specialties } from '@medone/medonehp-api-client';

import { fetchGridData, selectCensus, selectCensusFilters } from '../slice';
import { customExpandIcon } from '../../../../../shared/common/helpers/collapse';
import { PccCensusPaths } from '../../pcc-census/index.routes';
import { selectPatientDrawerOpen } from '../slice.patients';
import { isMobileView } from '../../../../../shared/common/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { selectFacilities } from '../../admin/slice.facilities';

import DischargeModal from '../../census/components/notes/discharge-modal';
import PatientTable from './patient-table';
import FacilityQuickNotesModal from './facility-quick-notes-modal';
import ResultsMobile from '../components-mobile/results';
import FacilityInfo from './facility-info';
import QuickNoteBadges from '../../components/quicknote-badges';

const { Panel } = Collapse;

const Results = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const censusFilters = useAppSelector<CensusFiltersDto>(selectCensusFilters);
    const census = useAppSelector<CensusGroupDto[]>(selectCensus);
    const patientDrawerOpen = useAppSelector<boolean>(selectPatientDrawerOpen);
    const facilities = useAppSelector<FacilityDto[]>(selectFacilities);
    const [quickNotesVisible, setQuickNotesVisible] = useState<boolean>(false);
    const [qnFacilityId, setQnFacilityId] = useState<number>(null);
    const [facilityName, setFacilityName] = useState<string>('');

    const handleViewPccCensusClick = (result: CensusGroupDto) => {
        history.push(`${PccCensusPaths.Home.Index}/${result.pointClickCareFacilityId}/${result.pointClickCareOrganizationUuId}`);
    };

    const renderPccCensusButton = (result: CensusGroupDto) => {
        return (
            result.pointClickCareFacilityId &&
            result.pointClickCareOrganizationUuId && (
                <Button key={`pcc-btn-${result.admittedToId}`} type="primary" size="small" className="pcc-btn" onClick={() => handleViewPccCensusClick(result)}>
                    <span className="label">View PointClickCare Census</span>
                </Button>
            )
        );
    };

    const showQuickNotes = (e, facilityId: number, facName: string) => {
        e.stopPropagation();

        setQnFacilityId(facilityId);
        setFacilityName(facName);
        setQuickNotesVisible(true);
    };

    const handleCollapseChange = useCallback(
        async (key: string) => {
            const facilityId = parseInt(key);
            const censusGroup = census.find((x) => x.admittedToId === facilityId);

            await dispatch(fetchGridData(censusGroup));

            localStorage.setItem('CensusResultsActivePanel', key);
        },
        [census, dispatch]
    );

    const renderFacilityTitle = (result: CensusDto) => {
        const facility = facilities.find((x) => x.id === result.admittedToId);

        return (
            <div className="d-inline-flex">
                <FacilityInfo facility={facility} />

                <p className="mb-0 ml-1">{result.admittedTo}</p>
            </div>
        );
    };

    const renderQuicknotesButton = (censusGroup: CensusGroupDto) => {
        const primary = censusGroup.specialtyNoteCounts.find((x) => x.specialty === Specialties.Primary)?.count;
        const pmr = censusGroup.specialtyNoteCounts.find((x) => x.specialty === Specialties.PMR)?.count;
        const wound = censusGroup.specialtyNoteCounts.find((x) => x.specialty === Specialties.WoundCare)?.count;

        if (primary == null && pmr == null && wound == null) {
            return (
                <Button
                    key={`quicknotes-${censusGroup.admittedToId}`}
                    type="primary"
                    size="small"
                    className="pcc-btn ml-1"
                    onClick={(e) => showQuickNotes(e, censusGroup.admittedToId, censusGroup.admittedTo)}
                >
                    <span className="label">View Quick Notes</span>
                </Button>
            );
        }

        return (
            <Button
                key={`quicknotes-${censusGroup.admittedToId}`}
                type="primary"
                size="small"
                className="pcc-btn ml-1"
                style={{ paddingRight: '13px' }}
                onClick={(e) => showQuickNotes(e, censusGroup.admittedToId, censusGroup.admittedTo)}
            >
                <span className="label">View Quick Notes</span>

                <span className="ml-1">
                    <QuickNoteBadges primary={primary} pmr={pmr} wound={wound} spaceSize={2} />
                </span>
            </Button>
        );
    };

    const renderCensus = () => {
        if (census == null || census.length <= 0) {
            if (censusFilters && (!censusFilters.locations || !censusFilters.locations.length)) {
                return <Alert message="Please select at least 1 location." type="info" showIcon />;
            }

            return <Alert message="No results found." type="info" showIcon />;
        }

        if (isMobileView()) {
            return <ResultsMobile showQuickNotes={showQuickNotes} renderFacilityTitle={renderFacilityTitle} />;
        }

        return (
            <Collapse
                accordion
                expandIconPosition="end"
                expandIcon={customExpandIcon}
                onChange={handleCollapseChange}
                defaultActiveKey={localStorage.getItem('CensusResultsActivePanel')}
            >
                {census.map((result) => (
                    <Panel
                        key={result.admittedToId}
                        header={renderFacilityTitle(result)}
                        extra={[
                            renderPccCensusButton(result),
                            renderQuicknotesButton(result),

                            <Fragment key={`pcc-tag-${result.admittedToId}`}>{result && <Tag color="#6C8095">{result.items.length} Patients(s)</Tag>}</Fragment>,
                        ]}
                    >
                        <PatientTable census={result.items} />
                    </Panel>
                ))}
            </Collapse>
        );
    };

    useEffect(() => {
        if (patientDrawerOpen) {
            setQuickNotesVisible(false);
        }
    }, [patientDrawerOpen]);

    useEffect(() => {
        const activePanel = localStorage.getItem('CensusResultsActivePanel');

        if (activePanel) {
            handleCollapseChange(activePanel);
        }
    }, [handleCollapseChange]);

    return (
        <>
            <DischargeModal />

            <FacilityQuickNotesModal isVisible={quickNotesVisible} toggleVisible={setQuickNotesVisible} facilityId={qnFacilityId} qnFacilityName={facilityName} />

            <Row gutter={48} className="content overflowY-auto">
                <Col span={24}>{renderCensus()}</Col>
            </Row>
        </>
    );
};

export default Results;
