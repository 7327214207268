import { Fragment, useEffect } from 'react';
import { Alert, Badge, Button, Col, Collapse, Row, Tag } from 'antd';
import { CensusDto, CensusGroupDto } from '@medone/medonehp-api-client';

import { fetchGridData, selectCensus } from '../slice';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';

import PatientTableMobile from '../components-mobile/patient-table';

const { Panel } = Collapse;

type Props = {
    showQuickNotes: (e, facilityId: number, facName: string) => void;
    renderFacilityTitle: (value: CensusDto) => JSX.Element;
};

const ResultsMobile = ({ showQuickNotes, renderFacilityTitle }: Props) => {
    const dispatch = useAppDispatch();
    const census = useAppSelector<CensusGroupDto[]>(selectCensus);

    const handleCollapseChange = (key: string) => {
        const facilityId = parseInt(key);
        const censusGroup = census.find((x) => x.admittedToId === facilityId);

        dispatch(fetchGridData(censusGroup));

        localStorage.setItem('CensusResultsActivePanel', key);
    };

    useEffect(() => {
        const activePanel = localStorage.getItem('CensusResultsActivePanel');
        if (activePanel) {
            handleCollapseChange(activePanel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return census == null || census.length <= 0 ? (
        <Alert message="No results found" type="info" showIcon />
    ) : (
        <Collapse className="mt-2" accordion expandIconPosition="end" onChange={handleCollapseChange} defaultActiveKey={localStorage.getItem('CensusResultsActivePanel')}>
            {census.map((result) => (
                <Panel
                    key={result.admittedToId}
                    header={
                        <Row>
                            <Col span={24}>
                                <Row>{renderFacilityTitle(result)}</Row>

                                <Row>
                                    <Col span={24}>
                                        <Badge key={`pcc-qnbadge-${result.admittedToId}`} count={result.quickNotesUnacknowledgedCount} offset={[-20, 0]}>
                                            <Button
                                                key={`quicknotes-${result.admittedToId}`}
                                                type="primary"
                                                size="small"
                                                className="pcc-btn mr-1"
                                                onClick={(e) => showQuickNotes(e, result.admittedToId, result.admittedTo)}
                                            >
                                                <span className="label">View Quick Notes</span>
                                            </Button>
                                        </Badge>

                                        <Fragment key={`pcc-tag-${result.admittedToId}`}>{result && <Tag color="#6C8095">{result.items.length} Result(s)</Tag>}</Fragment>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                >
                    <PatientTableMobile census={result.items} />
                </Panel>
            ))}
        </Collapse>
    );
};

export default ResultsMobile;
