import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import { store } from '../store';
import { initPccHubConnection } from '../../post-acute/features/post-acute/pcc-census/hubs';
import { initAdminHubConnection } from '../../post-acute/features/post-acute/admin/hubs';
import { initScheduleHubConnection } from '../../post-acute/features/post-acute/schedule/hubs';
import { initCensusHubConnection } from '../../post-acute/features/post-acute/census/hubs';
import { initRouteBuilderHubConnection } from '../../post-acute/features/post-acute/route-builder/hubs';
import { refreshCensusItem, refreshCensus } from '../../post-acute/features/post-acute/census/slice';
import { refreshNote } from '../../post-acute/features/post-acute/census/slice.notes';
import { initCCHubConnection } from '../../post-acute/features/post-acute/clinical-coordinator/hubs';
import { setOffline } from './slice';

import { initNewAdmitsHubConnection } from '../../post-acute/features/post-acute/new-admits/hubs';
import { initReturnToHospitalHubConnection } from '../../post-acute/features/post-acute/return-to-hospital/hubs';

import { getCachedToken } from '../common/auth/slice';
import { allowSignalrConnection, getEnvBaseUrl, isBedBoardKiosk } from '../common/helpers';
import { initBedBoardHubConnection } from '../../bedboard/hubs';
import { fetchIntakes, fetchIntakesByKioskId } from '../../bedboard/features/bedboard/slice';
import { BuildInfo } from '../../Config';

/**
 * HubWrapper class is used to manage the SignalR hub connection and provide methods to start and stop the connection.
 * It also handles reconnecting to the hub in case of connection failures.
 */
class HubWrapper {
    /**
     * The base URL for the application.
     *
     * @type {string}
     */
    private static baseUrl: string = getEnvBaseUrl();

    /**
     * The name of the group.
     *
     * @type {string}
     */
    private readonly _groupName: string = '';

    /**
     * Indicates whether the hub is enabled or not.
     *
     * @type {boolean}
     */
    private _hubEnabled: boolean = false;

    /**
     * Represents a hub connection object.
     *
     * @type {HubConnection}
     * @defaultValue null
     */
    private _hubConnection: HubConnection = null;

    /**
     * Represents the logging level used in the application.
     *
     * @typedef {('Error' | 'Warning' | 'Info' | 'Debug')}*/
    private _logLevel: LogLevel = LogLevel.Error;

    /**
     * Constructor for creating an instance of the class.
     * @param logLevel
     * @param {string} groupName - The name of the group.
     */
    public constructor(logLevel: LogLevel, groupName: string) {
        this._groupName = groupName;
        this._logLevel = logLevel;
    }

    /**
     * Stops the SignalR hub connection.
     *
     * @returns {Promise<void>} - A Promise that resolves once the hub connection is stopped.
     */
    public async stopHub(): Promise<void> {
        if (!this._hubEnabled) {
            return;
        }

        console.log('Disconnecting from SignalR...');

        this._hubEnabled = false;

        await this._hubConnection.stop();

        this._hubConnection = null;
    }

    /**
     * Delays the execution of the code for the given number of milliseconds.
     * @param {number} ms - The number of milliseconds to delay.
     * @return {Promise} - A promise that resolves after the specified delay.
     */
    private async delay(ms: number): Promise<any> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    /**
     * Calculates the delay based on the count of failures.
     *
     * @param {number} count - The count of failures.
     * @return {number} - The calculated delay in milliseconds.
     */
    private calculateDelay(count: number): number {
        return Math.min(
            30000, // but no longer than 30 seconds todal
            count * // increase for each failure
                Math.round(3000 + Math.random() * 2001)
        );
    }

    /**
     * Starts the SignalR hub connection.
     *
     * @return {Promise<void>} A promise that resolves when the hub connection is successfully started.
     */
    public async startHub(): Promise<void> {
        if (this._hubEnabled || !allowSignalrConnection()) {
            return;
        }

        let count = 1;

        while (!this._hubEnabled) {
            try {
                let connection = await this.buildHubConnection();

                this.configureConnection(connection);

                await this.startHubConnection(connection);

                this._hubConnection = connection;
                this._hubEnabled = true;

                store.dispatch(setOffline(false));
            } catch (err) {
                store.dispatch(setOffline(true));
                let delay = this.calculateDelay(count++);

                await this.delay(delay);
            }
        }
    }

    /**
     * Configures the connection to the hub server.
     *
     * @param {HubConnection} connection - The hub connection to configure.
     */
    private configureConnection(connection: HubConnection) {
        connection.onreconnecting((error) => {
            if (this._hubEnabled) {
                console.log(`Connection lost due to error "${error}". Reconnecting.`);
                store.dispatch(setOffline(true));
            }
        });

        connection.onreconnected(async (error) => {
            if (this._hubEnabled) {
                console.log(`Connection lost due to error "${error}". Reconnected.`);

                store.dispatch(setOffline(false));

                if (window.location.pathname.includes('/postacute/census')) {
                    const { currentCensus, currentNote } = store.getState().census;

                    // Reload current census data
                    if (currentCensus != null) {
                        await store.dispatch(refreshCensusItem(currentCensus));
                    }

                    // Reload current note data if it hasn't been signed yet
                    if (currentNote != null && currentNote.signedTimestamp == null) {
                        await store.dispatch(refreshNote(currentNote));
                    }

                    // Need to reconnect and redisplay the census and/or the Medical History information while on notes
                    await store.dispatch(refreshCensus());
                }

                // If on the bedboard dashboard, then we can just reload the intake table
                if (window.location.hostname.includes('bedboard') && window.location.pathname === '/') {
                    await store.dispatch(fetchIntakes(null, false));
                }

                // If a kiosk is reconnected, let's reload the data
                if (window.location.hostname.includes('bedboard') && isBedBoardKiosk()) {
                    const { kioskBedBoardIntakes } = store.getState().bedBoard;

                    if (kioskBedBoardIntakes != null) {
                        await store.dispatch(fetchIntakesByKioskId(kioskBedBoardIntakes.hospitalKioskId));
                    }
                }
            }
        });

        if (BuildInfo.IsBedBoard) {
            initBedBoardHubConnection(connection, this._groupName);
        } else {
            // Setup hub listeners here
            initAdminHubConnection(connection);
            initCensusHubConnection(connection);
            initScheduleHubConnection(connection);
            initPccHubConnection(connection);
            initRouteBuilderHubConnection(connection);
            initCCHubConnection(connection);
            initNewAdmitsHubConnection(connection);
            initReturnToHospitalHubConnection(connection);
        }
    }

    /**
     * Starts a hub connection asynchronously.
     *
     * @param {HubConnection} connection - The hub connection object to start.
     *
     * @return {Promise<void>} - A Promise that resolves when the hub connection is successfully started.
     */
    private async startHubConnection(connection: HubConnection): Promise<void> {
        if (!allowSignalrConnection()) {
            return;
        }

        console.log('Connecting to hub...');

        await connection.start();

        store.dispatch(setOffline(false));

        console.log('Connected to hub.');
    }

    /**
     * Builds a HubConnection with the specified hubUrl and accessToken.
     * @returns {Promise<HubConnection>} A Promise that resolves to a HubConnection object.
     */
    private async buildHubConnection(): Promise<HubConnection> {
        let hubUrl = `${HubWrapper.baseUrl}/api`;

        if (this._groupName != null && this._groupName !== '') {
            hubUrl = `${HubWrapper.baseUrl}/api?groupName=${this._groupName}`;
        }

        return new HubConnectionBuilder()
            .configureLogging(this._logLevel)
            .withUrl(hubUrl, {
                accessTokenFactory: () => {
                    return store.dispatch(getCachedToken());
                },
            })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext) => {
                    return this.calculateDelay(retryContext.previousRetryCount + 1); // 3 to 5 second retry interval.
                },
            })
            .build();
    }
}

/**
 * Represents a dictionary that maps keys to values.
 *
 * @typedef {Object} Dictionary
 * @template Key - The type of keys in the dictionary.
 * @template Value - The type of values in the dictionary.
 */
type Dictionary<Key extends keyof any, Value> = {
    [key in Key]: Value; // Mapped types syntax
};

// https://docs.microsoft.com/en-us/azure/azure-signalr/signalr-concept-serverless-development-config
// https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-3.1#error-handling-and-logging

/**
 * Represents a singleton class that manages hub instances.
 */
export class HubSingleton {
    private static _instance: HubSingleton | null = null;

    /**
     * Retrieves the instance of the HubSingleton class.
     *
     * @returns {HubSingleton} The singleton instance of the HubSingleton class.
     */
    public static get Instance(): HubSingleton {
        return (HubSingleton._instance ??= new HubSingleton());
    }

    private _hubMap: Dictionary<string, HubWrapper> = {};

    private constructor() {}

    /**
     * Represents the logging level for the application.
     *
     * @typedef {enum} LogLevel
     * @property {string} Error - Represents the error level log.
     */
    private static _defaultLogLevel: LogLevel = LogLevel.Error;

    /**
     * Represents the default group name.
     *
     * @type {string}
     */
    private static _defaultGroupName: string = 'default';

    /**
     * Stops the specified hub or the default hubs if groupName is not provided.
     *
     * @param {string} groupName - Optional.
     * The name of the hub to stop.
     * Defaults to an empty string which stops the default hub.
     *
     * @return {Promise<void>} Resolves with void when the hub(s) have been stopped.
     */
    public stopHub(groupName: string = ''): Promise<void> {
        let internalGroupName = groupName === '' ? HubSingleton._defaultGroupName : groupName;

        if (this._hubMap.hasOwnProperty(internalGroupName)) {
            return this._hubMap[internalGroupName].stopHub();
        }

        return Promise.resolve();
    }

    /**
     * Starts the hub for a given group name.
     *
     * @param {string} [groupName=""] - The name of the group to start the hub for.
     * If no group name is provided, the default group will be used.
     *
     * @return {Promise<void>} - A Promise that resolves once the hub has started.
     */
    public startHub(groupName: string = ''): Promise<void> {
        let internalGroupName = groupName === '' ? HubSingleton._defaultGroupName : groupName;

        if (!this._hubMap.hasOwnProperty(internalGroupName)) {
            this._hubMap[internalGroupName] = new HubWrapper(HubSingleton._defaultLogLevel, groupName);
        }

        return this._hubMap[internalGroupName].startHub();
    }
}
