import { uniq } from 'lodash';
import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';
import {
    PccPatientClient,
    PccPatientDataDto,
    PatientSearchResultDto,
    PatientDto,
    PatientClient,
    PatientSearchDto,
    CensusDto,
    PatientIntakeDto,
    CensusGroupDto,
    StagedNoteDto,
    PatientMergeRequestDto,
    PatientSearchResultsDto,
    PccProgressNoteDetailAggregationDto,
    PatientProviderSupplierDto,
    LookupClient,
    SearchNpiProviderRequestDto,
    SearchResponseOfNpiProviderInfoDto,
    State,
} from '@medone/medonehp-api-client';

import { Axios } from '../../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../../shared/store';

import { CensusState, PccPatientLoadingState } from './models';
import { censusSlice, fetchCensusByPatientIntakeId } from './slice';
import { handleError } from '../../../../shared/common/HandleErrors';
import { fetchPatientNotes } from './slice.patient-notes';
import { fetchRecentLabResults } from './slice.patient-labs';
import { getAllPatientIntakesByPatientId } from './slice.patient-intakes';
import { fetchSpecialties } from './slice.specialties';
import { renderPatientFullName } from '../../../../shared/common/helpers';

export const reducers = {
    setPccPatientLoading: (state: CensusState, action: PayloadAction<PccPatientLoadingState>) => {
        state.pccPatientLoading = action.payload;
    },

    setPccPatient: (state: CensusState, action: PayloadAction<PccPatientDataDto>) => {
        if (action.payload != null && action.payload.pccCensus != null) {
            state.pccPatient = action.payload;
        } else {
            state.pccPatient = null;
        }

        state.pccPatientLoading = null;
    },

    setPccProgressNoteDetailLoading: (state: CensusState, action: PayloadAction<boolean>) => {
        state.pccPatientProgressNoteDetailsLoading = action.payload;
    },

    setPccProgressNoteDetail: (state: CensusState, action: PayloadAction<PccProgressNoteDetailAggregationDto>) => {
        state.pccPatientProgressNoteDetails = action.payload;
    },

    setPatient: (state: CensusState, action: PayloadAction<PatientDto>) => {
        state.patient = action.payload;
        state.patientLabProcessing = false;

        if (!action.payload) {
            state.patientNotes = null;
            state.completedNotes = null;
            state.incompleteNotes = null;
            state.quickNotes = null;
            state.patientDocumentGroups = [];
            state.recentLabs = [];
            state.recentLabTestDetails = [];
            state.currentPatientNote = null;
            state.providersAndSuppliers = null;
            state.awvPreventativeItem = null;
            state.patientHospiceIntakes = [];
            state.noteFaxes = [];
        }
    },

    setSearchResults: (state: CensusState, action: PayloadAction<PatientSearchResultsDto>) => {
        state.searchResults = action.payload;
        state.patientSearchLoading = false;
        state.patientSearchVisible = true;
    },

    updateSearchResultsWithPatient: (state: CensusState, action: PayloadAction<PatientDto>) => {
        if (state.searchResults?.results && action.payload) {
            const { id, dateOfBirth } = action.payload;

            state.searchResults.results = [...state.searchResults.results].map((result) => {
                if (result.patientId === id) {
                    return { ...result, name: renderPatientFullName(action.payload, true), dateOfBirth } as PatientSearchResultDto;
                }

                return result;
            });
        }
    },

    updateSearchResultsWithPatientIntake: (state: CensusState, action: PayloadAction<PatientIntakeDto>) => {
        if (action.payload) {
            const { id, patientId, dateOfBirth, age, roomNumber, gender, hospitalMRN } = action.payload;

            if (state.census) {
                const newCensus = state.census.map((censusItems) => {
                    const newItems = [...censusItems.items].map((item) => {
                        if (item.patientId === patientId && item.patientIntakeId === id) {
                            return { ...item, name: renderPatientFullName(action.payload, true), dateOfBirth, age } as CensusDto;
                        }

                        return item;
                    });

                    return { ...censusItems, items: newItems } as CensusGroupDto;
                });

                state.census = newCensus;
            }

            if (state.currentCensus && state.currentCensus.patientId === patientId && state.currentCensus.patientIntakeId === id) {
                state.currentCensus = { ...state.currentCensus, name: renderPatientFullName(action.payload, true), dateOfBirth, age, roomNumber, gender, hospitalMRN } as CensusDto;
            }

            if (state.searchResults?.results) {
                state.searchResults.results = [...state.searchResults.results].map((result) => {
                    if (result.patientIntakes.map((x) => x.patientIntakeId).includes(id) && result.patientId === patientId) {
                        return { ...result, name: renderPatientFullName(action.payload, true), dateOfBirth, age } as PatientSearchResultDto;
                    }

                    return result;
                });
            }
        }
    },

    setPatientSearchLoading: (state: CensusState, action: PayloadAction<boolean>) => {
        state.patientSearchLoading = action.payload;
    },

    setPatientSearchVisible: (state: CensusState, action: PayloadAction<boolean>) => {
        state.patientSearchVisible = action.payload;
    },

    setPatientDrawerOpen: (state: CensusState, action: PayloadAction<boolean>) => {
        state.patientDrawerOpen = action.payload;
    },

    setPatientProvidersAndSuppliers: (state: CensusState, action: PayloadAction<PatientProviderSupplierDto[]>) => {
        state.providersAndSuppliers = action.payload;
    },
};

export function clearPatientInfo(): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(censusSlice.actions.setPccPatient(null));
        dispatch(censusSlice.actions.setPatient(null));
    };
}

export function togglePatientDrawer(census: CensusDto | null): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(censusSlice.actions.setPatientDrawerOpen(census ? true : false));

        await dispatch(clearPatientInfo());

        dispatch(censusSlice.actions.clearPatientIntake());
        dispatch(censusSlice.actions.setCurrentCensus(census));
        dispatch(censusSlice.actions.setRecentLabs([]));
        dispatch(censusSlice.actions.setRecentLabsLoading(true));

        if (census) {
            if (census.patientId) {
                await dispatch(fetchPatient(census.patientId));
            } else {
                toast.error('Census does not have a patient id');
            }
        }
    };
}

export function togglePatientDrawerStagedNote(stagedNote: StagedNoteDto | null): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        await dispatch(fetchCensusByPatientIntakeId(stagedNote.patientIntakeId, false));

        const { currentCensus } = getState().census;

        await dispatch(togglePatientDrawer(currentCensus));
    };
}

export function fetchPatient(patientId: number): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new PatientClient(null, Axios);

        try {
            const response = await client.getById(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPatient(response.result.entity));

                dispatch(fetchPatientNotes(patientId));
                dispatch(fetchRecentLabResults(patientId));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchPccPatient(patientIntakeId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentPatientIntake, pccPatient, pccPatientLoading } = getState().census;
        const client = new PccPatientClient(null, Axios);

        const pccPatientIntakeId = pccPatient?.pccCensus?.patientIntakeId;
        const currentPatientIntakeId = currentPatientIntake?.id;

        dispatch(
            censusSlice.actions.setPccPatientLoading({
                patientIntakeId,
                loading: false,
            })
        );

        if (
            (pccPatientIntakeId && currentPatientIntakeId && pccPatientIntakeId === currentPatientIntakeId) ||
            (pccPatientLoading?.loading === true && pccPatientLoading.patientIntakeId === patientIntakeId)
        ) {
            // Prevent duplicate loads for the current patient
            return;
        }

        dispatch(
            censusSlice.actions.setPccPatientLoading({
                patientIntakeId,
                loading: true,
            })
        );

        dispatch(censusSlice.actions.setPccPatient(null));

        try {
            const response = await client.get(patientIntakeId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPccPatient(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchPccProgressNoteDetail(orgUuid: string, pccProgressNoteId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(censusSlice.actions.setPccProgressNoteDetailLoading(true));

        const client = new PccPatientClient(null, Axios);

        try {
            const response = await client.getProgressNoteDetails(orgUuid, pccProgressNoteId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPccProgressNoteDetail(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function patientSearch(searchString: string, currentPage = 1, pageSize = 10): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(censusSlice.actions.setPatientSearchLoading(true));

        const client = new PatientClient(null, Axios);

        try {
            const requestDto = PatientSearchDto.fromJS({
                searchString: searchString?.trim(),
                currentPage,
                pageSize,
            });

            if (searchString.length > 2) {
                const response = await client.search(requestDto);

                if (response.result.succeeded) {
                    const allIntakeIds = uniq(response.result.entity.results.flatMap((n) => n.patientIntakes.flatMap((y) => y.patientIntakeId)));

                    await dispatch(fetchSpecialties(allIntakeIds));

                    dispatch(censusSlice.actions.setSearchResults(response.result.entity));
                }

                return response.result.succeeded;
            } else {
                dispatch(censusSlice.actions.setSearchResults(null));
            }

            return true;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        dispatch(censusSlice.actions.setPatientSearchLoading(false));

        return false;
    };
}

export function mergePatients(survivingId: number, mergeId: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientClient(null, Axios);

        try {
            const data = PatientMergeRequestDto.fromJS({ survivingId, mergeId });
            const response = await client.merge(data);

            if (!response.result.succeeded) {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            } else {
                const { currentPatientIntake } = getState().census;

                // Reload their patient intake history
                if (currentPatientIntake != null) {
                    await dispatch(getAllPatientIntakesByPatientId(survivingId, currentPatientIntake.id));
                }
            }

            return response.result.succeeded;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return false;
    };
}

export function getProvidersAndSuppliers(patientId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new PatientClient(null, Axios);

        try {
            const response = await client.getProvidersAndSuppliers(patientId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setPatientProvidersAndSuppliers(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function npiProviderSearch(searchTerm: string, currentPage = 1, pageSize = 10, state = State.OH): AppThunk<Promise<SearchResponseOfNpiProviderInfoDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new LookupClient(null, Axios);

        try {
            const requestDto = {
                searchTerm: searchTerm,
                stateFilter: state,
                currentPage: currentPage,
                pageSize: pageSize,
            } as SearchNpiProviderRequestDto;

            const response = await client.searchNpiProvider(requestDto);

            if (response.result.succeeded) {
                return response.result.entity;
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export const selectPccPatientLoading = (state: RootState) => state.census.pccPatientLoading;
export const selectPccPatient = (state: RootState) => state.census.pccPatient;
export const selectPccPatientNoteDetail = (state: RootState) => state.census.pccPatientProgressNoteDetails;
export const selectPatient = (state: RootState) => state.census.patient;
export const selectSearchResults = (state: RootState) => state.census.searchResults;
export const selectPatientSearchLoading = (state: RootState) => state.census.patientSearchLoading;
export const selectPatientSearchVisible = (state: RootState) => state.census.patientSearchVisible;
export const selectPatientDrawerOpen = (state: RootState) => state.census.patientDrawerOpen;
export const selectPatientProvidersAndSuppliers = (state: RootState) => state.census.providersAndSuppliers;
