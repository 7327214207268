import {
    BedBoardIntakeDataDto,
    BedBoardIntakeDto,
    BedBoardIntakeChangeLogDto,
    BedBoardIntakeSearchFilterDto,
    BedBoardKioskBedBoardIntakesDto,
    BedBoardPostAcutePatientDataDto,
    BedBoardHospitalFilterDto,
} from '@medone/medonehp-bedboard-client';

export interface BedBoardState {
    errorMessage: string | null;
    areIntakesLoading: boolean;
    isInvalidSearchCriteria: boolean;
    intakes: BedBoardIntakeDto[];
    recentIntakesForMRN: BedBoardIntakeDto[];
    currentIntake: BedBoardIntakeDataDto;
    currentIntakeChangeLogs: BedBoardIntakeChangeLogDto[];
    currentIntakePostAcutePatientData: BedBoardPostAcutePatientDataDto;
    currentIntakePostAcuteLoading: boolean;
    showEditIntake: boolean;
    isEditLoading: boolean;
    isHistoryLoading: boolean;
    viewNoteMarkdown: string;
    kioskBedBoardIntakes: BedBoardKioskBedBoardIntakesDto;
    lastFetchFilterValues: BedBoardIntakeSearchFilterDto;
    bedBoardHospitalFilters: BedBoardHospitalFilterDto[];
    fadeOutAssignedCard?: number;
    lastAssignedIntakeId?: number;
}

export const initialState: BedBoardState = {
    errorMessage: null,
    areIntakesLoading: false,
    isInvalidSearchCriteria: false,
    intakes: null,
    recentIntakesForMRN: null,
    currentIntake: null,
    currentIntakeChangeLogs: null,
    currentIntakePostAcutePatientData: null,
    currentIntakePostAcuteLoading: false,
    showEditIntake: false,
    isEditLoading: false,
    isHistoryLoading: false,
    viewNoteMarkdown: null,
    kioskBedBoardIntakes: null,
    lastFetchFilterValues: null,
    bedBoardHospitalFilters: [],
    fadeOutAssignedCard: null,
    lastAssignedIntakeId: null,
};
