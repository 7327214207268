import {
    
    BedBoardHospitalFilterClient,
    BedBoardHospitalFilterDto,
    BedBoardIntakeDataDto,
    BedBoardIntakeDto,
    BedBoardIntakeHistoryDataDto,
    BedBoardIntakePublicDto,
    BedBoardIntakesClient,
    BedBoardIntakeSearchFilterDto,
    BedBoardKioskBedBoardIntakesDto,
    BedBoardPostAcutePatientDataDto,
    BedBoardUserFiltersClient,
} from '@medone/medonehp-bedboard-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isBedBoardKiosk, toCamel } from '../../../shared/common/helpers';
import { Axios } from '../../../shared/common/http';
import { AppThunk, AppDispatch, RootState } from '../../../shared/store';

import { initialState, BedBoardState } from './models';
import { handleError } from '../../../shared/common/HandleErrors';
import { fetchHospitals } from './admin/slice.hospitals';

export const bedBoardIntakeFilterKey = 'bedboard-intake-filter';

export const bedBoardSlice = createSlice({
    name: 'bedBoard',
    initialState,
    reducers: {
        setError: (state: BedBoardState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },

        setAreIntakesLoading: (state: BedBoardState, action: PayloadAction<boolean>) => {
            state.areIntakesLoading = action.payload;
        },

        setIsInvalidSearchCriteria: (state: BedBoardState, action: PayloadAction<boolean>) => {
            state.isInvalidSearchCriteria = action.payload;
        },

        setIntakes: (state: BedBoardState, action: PayloadAction<BedBoardIntakeDto[]>) => {
            state.intakes = action.payload;
            state.fadeOutAssignedCard = null;
        },

        setRecentIntakesForMRN: (state: BedBoardState, action: PayloadAction<BedBoardIntakeDto[]>) => {
            state.recentIntakesForMRN = action.payload;
        },

        setLastFetchFilterValues: (state: BedBoardState, action: PayloadAction<BedBoardIntakeSearchFilterDto>) => {
            state.lastFetchFilterValues = action.payload;
        },

        setCurrentIntake: (state: BedBoardState, action: PayloadAction<BedBoardIntakeDataDto>) => {
            state.currentIntake = action.payload;
        },

        setCurrentIntakeChangeLogs: (state: BedBoardState, action: PayloadAction<BedBoardIntakeHistoryDataDto>) => {
            if (action.payload == null) {
                state.currentIntakeChangeLogs = null;
            } else if (state.currentIntake && state.currentIntake.id === action.payload.intakeId) {
                state.currentIntakeChangeLogs = action.payload.changeLogs;
            }
        },

        setCurrentIntakePostAcutePatientData: (state: BedBoardState, action: PayloadAction<BedBoardPostAcutePatientDataDto>) => {
            state.currentIntakePostAcutePatientData = action.payload;
        },

        setCurrentIntakePostAcuteLoading: (state: BedBoardState, action: PayloadAction<boolean>) => {
            state.currentIntakePostAcuteLoading = action.payload;
        },

        setShowEditIntake: (state: BedBoardState, action: PayloadAction<boolean>) => {
            state.showEditIntake = action.payload;
        },

        setIsEditLoading: (state: BedBoardState, action: PayloadAction<boolean>) => {
            state.isEditLoading = action.payload;
        },

        setIsHistoryLoading: (state: BedBoardState, action: PayloadAction<boolean>) => {
            state.isHistoryLoading = action.payload;
        },

        setViewNoteMarkdown: (state: BedBoardState, action: PayloadAction<string>) => {
            state.viewNoteMarkdown = action.payload;
        },

        setKioskIntakes: (state: BedBoardState, action: PayloadAction<BedBoardKioskBedBoardIntakesDto>) => {
            state.kioskBedBoardIntakes = action.payload;
        },

        setBedBoardHospitalFilters: (state: BedBoardState, action: PayloadAction<BedBoardHospitalFilterDto[]>) => {
            state.bedBoardHospitalFilters = action.payload;
        },

        setFadeOutAssignedCard: (state: BedBoardState, action: PayloadAction<number>) => {
            state.fadeOutAssignedCard = action.payload;
        },

        setLastAssignedIntakeId: (state: BedBoardState, action: PayloadAction<number>) => {
            state.lastAssignedIntakeId = action.payload;
        },
    },
});

export const getDefaultBedBoardIntakeSearchFilter = (filters?: BedBoardIntakeSearchFilterDto) => {
    if (filters == null) {
        filters = BedBoardIntakeSearchFilterDto.fromJS({
            hospitalIds: [],
        });
    }

    return filters;
};

export function setFadeOutAssignedCard(intakeId: number): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(bedBoardSlice.actions.setFadeOutAssignedCard(intakeId));

        // If clearing the intakeId, lets reload the grid data to refresh the screen
        if (intakeId == null) {
            dispatch(fetchIntakes(null, false));
        }
    };
}

export function setLastAssignedIntakeId(intakeId: number): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(bedBoardSlice.actions.setLastAssignedIntakeId(intakeId));
    };
}

export function clearCurrentIntake(): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(bedBoardSlice.actions.setShowEditIntake(false));
        dispatch(bedBoardSlice.actions.setCurrentIntake(null));
        dispatch(bedBoardSlice.actions.setCurrentIntakeChangeLogs(null));
        dispatch(bedBoardSlice.actions.setCurrentIntakePostAcutePatientData(null));
        dispatch(bedBoardSlice.actions.setViewNoteMarkdown(null));
        dispatch(bedBoardSlice.actions.setRecentIntakesForMRN(null));
    };
}

export function fetchFilter(filterKey: string, defaultValue?: any): AppThunk<Promise<any>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardUserFiltersClient( null, Axios);

        try {
            const response = await client.getByKey(filterKey);

            if (response.result.entity != null) {
                const json = JSON.parse(response.result.entity.filterValue || '');

                return toCamel(json);
            }
        } catch (error) {
            console.error(error);
        }

        return defaultValue || {};
    };
}

export function fetchIntakesByKioskId(publicId: string): AppThunk {
    // This method should ONLY be called by the kiosks, otherwise we don't want to do anything
    if (!isBedBoardKiosk()) {
        throw Error('Invalid request for kiosk.');
    }

    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardIntakesClient( null, Axios);

        try {
            const response = await client.getAllByKioskId(publicId);

            await dispatch(fetchHospitals());

            if (response.result.succeeded) {
                dispatch(bedBoardSlice.actions.setKioskIntakes(response.result.entity));
            } else {
                dispatch(bedBoardSlice.actions.setKioskIntakes(null));
                dispatch(bedBoardSlice.actions.setError(response.result.message));
            }
        } catch (error) {
            dispatch(bedBoardSlice.actions.setKioskIntakes(null));
            dispatch(bedBoardSlice.actions.setError(error.toString()));
        }
    };
}

export function fetchIntakes(filters?: BedBoardIntakeSearchFilterDto, showLoading = true): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const { lastFetchFilterValues } = getState().bedBoard;

        const client = new BedBoardIntakesClient( null, Axios);

        if (showLoading) {
            dispatch(bedBoardSlice.actions.setAreIntakesLoading(true));
        }

        dispatch(bedBoardSlice.actions.setIsInvalidSearchCriteria(false));

        try {
            if (filters == null) {
                filters = lastFetchFilterValues;
            }

            if (filters == null) {
                const savedFilters = await dispatch(fetchFilter(bedBoardIntakeFilterKey, getDefaultBedBoardIntakeSearchFilter()));
                if (savedFilters != null && savedFilters !== '') {
                    filters = BedBoardIntakeSearchFilterDto.fromJS(savedFilters);
                }
            }

            const dto = filters || BedBoardIntakeSearchFilterDto.fromJS({});
            const response = await client.searchAll(dto);

            if (response.result.succeeded) {
                dispatch(bedBoardSlice.actions.setIntakes(response.result.entity));
                dispatch(bedBoardSlice.actions.setLastFetchFilterValues(dto));
            } else {
                dispatch(bedBoardSlice.actions.setIsInvalidSearchCriteria(true));

                // Don't show this as a toast error as it already shows on the page as an alert
                if (response.result.message !== 'Please select a hospital') {
                    dispatch(bedBoardSlice.actions.setError(response.result.message));
                }
            }
        } catch (error) {
            handleError(error);

            dispatch(bedBoardSlice.actions.setError(error.toString()));
        } finally {
            dispatch(bedBoardSlice.actions.setAreIntakesLoading(false));
        }
    };
}

export function fetchIntakeData(id: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardIntakesClient( null, Axios);

        try {
            dispatch(bedBoardSlice.actions.setShowEditIntake(true));
            dispatch(bedBoardSlice.actions.setIsEditLoading(true));

            const response = await client.getIntakeData(id);

            if (response.result.succeeded) {
                dispatch(bedBoardSlice.actions.setCurrentIntake(response.result.entity));
                dispatch(bedBoardSlice.actions.setRecentIntakesForMRN(response.result.entity.recentIntakesForMRN));
            } else {
                dispatch(bedBoardSlice.actions.setError(response.result.message));
            }
        } catch (error) {
            dispatch(bedBoardSlice.actions.setError(error.toString()));
            dispatch(bedBoardSlice.actions.setShowEditIntake(false));
        } finally {
            dispatch(bedBoardSlice.actions.setIsEditLoading(false));
        }
    };
}

export function fetchIntakeHistory(id: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardIntakesClient( null, Axios);

        try {
            dispatch(bedBoardSlice.actions.setIsHistoryLoading(true));

            const response = await client.getBedboardIntakeChangeLogs(id);

            if (response.result.succeeded) {
                dispatch(bedBoardSlice.actions.setCurrentIntakeChangeLogs(response.result.entity));
            } else {
                dispatch(bedBoardSlice.actions.setError(response.result.message));
            }
        } catch (error) {
            dispatch(bedBoardSlice.actions.setError(error.toString()));
        } finally {
            dispatch(bedBoardSlice.actions.setIsHistoryLoading(false));
        }
    };
}

export function createIntake(intake: BedBoardIntakeDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardIntakesClient( null, Axios);

        try {
            if (intake.isStat == null) {
                intake.isStat = false;
            }

            const response = await client.post(intake);

            if (response.result.succeeded) {
                return true;
            } else {
                dispatch(bedBoardSlice.actions.setError(response.result.message));
            }
        } catch (error) {
            dispatch(bedBoardSlice.actions.setError(error.toString()));
        }

        return false;
    };
}

export function updateIntake(intake: BedBoardIntakeDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardIntakesClient( null, Axios);

        try {
            if (intake.isStat == null) {
                intake.isStat = false;
            }

            const response = await client.postIntakeData(intake);

            if (response.result.succeeded) {
                dispatch(bedBoardSlice.actions.setCurrentIntake(response.result.entity));
            } else {
                dispatch(bedBoardSlice.actions.setError(response.result.message));
            }

            return response.result.succeeded;
        } catch (error) {
            dispatch(bedBoardSlice.actions.setError(error.toString()));
        }

        return false;
    };
}

export function fetchIntakePostAcutePatientData(patientId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardIntakesClient( null, Axios);

        dispatch(bedBoardSlice.actions.setCurrentIntakePostAcuteLoading(true));

        try {
            const response = await client.getPostAcutePatientData(patientId);

            if (response.result.succeeded) {
                const current = getState().bedBoard.currentIntake;
                if (response.result.entity && current && response.result.entity.patientId === current.postAcutePatientId) {
                    dispatch(bedBoardSlice.actions.setCurrentIntakePostAcutePatientData(response.result.entity));
                }
            } else {
                dispatch(bedBoardSlice.actions.setError(response.result.message));
            }
        } catch (error) {
            dispatch(bedBoardSlice.actions.setError(error.toString()));
        } finally {
            dispatch(bedBoardSlice.actions.setCurrentIntakePostAcuteLoading(false));
        }
    };
}

export function fetchNoteMarkdown(noteId: number): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardIntakesClient( null, Axios);

        try {
            const response = await client.getNoteMarkdown(noteId);

            if (response.result.succeeded) {
                dispatch(bedBoardSlice.actions.setViewNoteMarkdown(response.result.entity));
            } else {
                dispatch(bedBoardSlice.actions.setError(response.result.message));
            }
        } catch (error) {
            dispatch(bedBoardSlice.actions.setError(error.toString()));
        }
    };
}

export function createIntakePublic(intake: BedBoardIntakePublicDto): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardIntakesClient( null, Axios);

        try {
            if (intake.isStat == null) {
                intake.isStat = false;
            }

            const response = await client.postPublic(intake);

            if (response.result.succeeded) {
                return true;
            } else {
                dispatch(bedBoardSlice.actions.setError(response.result.message));
            }
        } catch (error) {
            dispatch(bedBoardSlice.actions.setError(error.toString()));
        }

        return false;
    };
}

export function fetchBedBoardHospitalFilters(): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardHospitalFilterClient( null, Axios);

        try {
            const response = await client.getAll();

            if (response.result.succeeded) {
                dispatch(bedBoardSlice.actions.setBedBoardHospitalFilters(response.result.entity));
            } else {
                dispatch(bedBoardSlice.actions.setError(response.result.message));
            }
        } catch (error) {
            dispatch(bedBoardSlice.actions.setError(error.toString()));
        }
    };
}

export function deleteIntake(intakeId: number): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        
        const client = new BedBoardIntakesClient( null, Axios);

        try {
            const response = await client.delete(intakeId);

            if (response.result.succeeded) {
                return true;
            } else {
                dispatch(bedBoardSlice.actions.setError(response.result.message));
            }
        } catch (error) {
            dispatch(bedBoardSlice.actions.setError(error.toString()));
        }

        return false;
    };
}

export const selectError = (state: RootState) => state.bedBoard.errorMessage;
export const clearError = () => bedBoardSlice.actions.setError(null);
export const selectIsInvalidSearchCriteria = (state: RootState) => state.bedBoard.isInvalidSearchCriteria;
export const selectAreIntakesLoading = (state: RootState) => state.bedBoard.areIntakesLoading;
export const selectIntakes = (state: RootState) => state.bedBoard.intakes;
export const selectCurrentIntake = (state: RootState) => state.bedBoard.currentIntake;
export const selectCurrentIntakeChangeLogs = (state: RootState) => state.bedBoard.currentIntakeChangeLogs;
export const selectRecentIntakesForMRN = (state: RootState) => state.bedBoard.recentIntakesForMRN;
export const selectCurrentIntakePostAcuteLoading = (state: RootState) => state.bedBoard.currentIntakePostAcuteLoading;
export const selectShowEditIntake = (state: RootState) => state.bedBoard.showEditIntake;
export const selectIsEditLoading = (state: RootState) => state.bedBoard.isEditLoading;
export const selectCurrentIntakePostAcutePatientData = (state: RootState) => state.bedBoard.currentIntakePostAcutePatientData;
export const selectViewNoteMarkdown = (state: RootState) => state.bedBoard.viewNoteMarkdown;
export const selectKioskBedBoardIntakes = (state: RootState) => state.bedBoard.kioskBedBoardIntakes;
export const clearViewNoteMarkdown = () => bedBoardSlice.actions.setViewNoteMarkdown(null);
export const selectBedBoardHospitalFilters = (state: RootState) => state.bedBoard.bedBoardHospitalFilters;
export const selectFadeOutAssignedCard = (state: RootState) => state.bedBoard.fadeOutAssignedCard;
export const selectIsHistoryLoading = (state: RootState) => state.bedBoard.isHistoryLoading;

export default bedBoardSlice.reducer;
