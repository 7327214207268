import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Alert, Col, Row, Skeleton, Tag } from 'antd';
import { FacilityDto, QuickNoteDto } from '@medone/medonehp-api-client';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Breaks from 'remark-breaks';

import logo from '../../../../shared/images/print-logo.jpg';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { printQuickNote, selectPrintQuickNote } from '../census/slice.notes';
import { selectFacilities } from '../admin/slice.facilities';
import { formatDateTimeLocal } from '../../../../shared/common/helpers';
import { renderQuickNoteCreatedBy } from '../../../../shared/common/helpers/renderers';

type DetailsParams = { ids: string; facilityId?: string };

const QuickNotePrint = () => {
    const params = useParams<DetailsParams>();
    const dispatch = useAppDispatch();
    const facilities = useAppSelector<FacilityDto[]>(selectFacilities);
    const quickNote = useAppSelector<QuickNoteDto>(selectPrintQuickNote);
    const { ids, facilityId } = params;

    const printNote = () => {
        setTimeout(() => window.print(), 1500);
    };

    const renderFacility = () => {
        if (facilityId) {
            const facility = (facilities ?? []).find((f) => f.id === parseInt(facilityId));

            return facility ? <>at {facility.name}</> : null;
        }

        return null;
    };

    const noteCreatedDate = (note: QuickNoteDto) => {
        return (
            <div>
                <Tag color="blue">
                    <strong>Created By:</strong> {renderQuickNoteCreatedBy(note)}
                </Tag>

                <Tag color="blue">
                    <strong>Created Date:</strong> {formatDateTimeLocal(note.createdDate, '', 'L LT')}
                </Tag>
            </div>
        );
    };

    const noteAcknowledgedDate = (note: QuickNoteDto) => {
        if (!note.isNotAcknowledged && note.acknowledgements && note.acknowledgements.length > 0) {
            return (
                <>
                    {note.acknowledgements.map((v) => {
                        return (
                            <div key={`ack-${v.id}`} className="mb-1">
                                <Tag color="green">
                                    <strong>Acknowledged By:</strong> {v.acknowledgedByUserName} <strong>on</strong> {formatDateTimeLocal(v.acknowledgedTimestamp)}
                                </Tag>
                            </div>
                        );
                    })}
                </>
            );
        }

        return null;
    };

    const renderPrintNote = () => {
        if (quickNote) {
            return (
                <>
                    <Row gutter={0} className="print-logo">
                        <Col span={24}>
                            <img src={logo} alt="Med One Logo" />
                        </Col>
                    </Row>

                    <div className="p-2">
                        <h2 className="mb-1">
                            Quick Note for {quickNote.patientName} {renderFacility()}
                        </h2>

                        <div className="mb-2">
                            <ReactMarkdown children={quickNote.value} remarkPlugins={[remarkGfm, Breaks]} />
                        </div>

                        {noteAcknowledgedDate(quickNote)}
                        {noteCreatedDate(quickNote)}

                        {quickNote.needsFollowUp && (
                            <Tag color="blue" className="followup-tag">
                                Needs followup
                            </Tag>
                        )}
                    </div>
                </>
            );
        }

        return <Alert type="warning" message="Unable to load note data." />;
    };

    useEffect(() => {
        async function getNote() {
            await dispatch(printQuickNote(parseInt(ids))); // Make sure we pass false here to log a manual print
        }

        if (quickNote == null) {
            getNote();
        } else if (quickNote) {
            printNote();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, ids, quickNote]);

    return (
        <Skeleton active loading={!quickNote}>
            <div className="print-note">{renderPrintNote()}</div>
        </Skeleton>
    );
};

export default QuickNotePrint;
